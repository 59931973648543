<template>
	<div class="home" ref="homme">
		<van-pull-refresh v-model="isLoading" @refresh="onRefresh" :loading-text="loadingText" :pulling-text="pullingText" :loosing-text="loosingText">
			<van-list v-model="loading" :finished="finished" :finished-text="bomTitle" @load="onLoad" :offset="20" class="main" loading-text="Loading...">
				<div class="newsList" v-for="(item, index) in list" :key="index">
					<div class="newsList_center" v-if="!item.type" @click="goLanding(item.id)">
						<div class="word">
							<div class="title">{{ item.title }}</div>
							<div class="newsInfo">
								<div>
									{{ item.channel }}
								</div>
								<div class="round"></div>
								<div>
									{{
                    $global.formatDate(item.publishedTime, "yyyy-MM-dd hh:mm")
                  }}
								</div>
							</div>
						</div>
						<div class="picurl">
							<img v-lazy="item.headImg" alt="" />
						</div>
					</div>

					<div class="adsItem" v-if="item.type && item.type === -1">
						<ins ref="ads1st" class="adsbygoogle" style="display: block" :data-ad-client="shell.client" :data-ad-slot="shell.slot1" data-ad-format="auto" data-full-width-responsive="true"></ins>
					</div>
					<div class="adsItem" v-if="item.type && item.type === -2">
						<ins class="adsbygoogle" style="display: block" :data-ad-client="shell.client" :data-ad-slot="shell.slot2" data-ad-format="auto" data-full-width-responsive="true"></ins>
					</div>

				</div>
			</van-list>
		</van-pull-refresh>

		<div class="loading" v-if="!requirDataAlready">
			<img src="@/assets/news/loading.png" alt="" />
		</div>
	</div>
</template>
<script>
import "@/css/news/home.scss";
export default {
	name: "home",
	data() {
		return {
			list: [],
			hasNext: false,
			isLoading: false, // 控制下拉刷新的加载状态
			loading: false, // 控制上拉加载的加载状态
			finished: false, // 是否已加载完全部数据
			bomTitle: "-- END --", // 上拉加载完成时的文本
			loadingText: "Under loading...", // 加载过程提示文案
			pullingText: "Pull down to refresh...", //下拉过程提示文案
			loosingText: "Release to refresh...", // 释放过程提示文案
			page: 1,
			type: "",
			isinitialization: false,
			isData: false,
			requirDataAlready: true,
			shell: null,
			firstAdVisible: false,
		};
	},
	mounted() {
		this.initPage();
		this.setupIframeTracking();
		this.loadAdSenseScript();
		const hiddenProperty =
			"hidden" in document
				? "hidden"
				: "webkitHidden" in document
				? "webkitHidden"
				: "mozHidden" in document
				? "mozHidden"
				: null;
		const visibilityChangeEvent = hiddenProperty.replace(
			/hidden/i,
			"visibilitychange"
		);

		const onVisibilityChange = () => {
			if (!document[hiddenProperty]) {
				console.log("页面激活状态");
				this.msg2client("page_active");
			} else {
				console.log("页面非激活状态");
				this.msg2client("page_inactive");
			}
		};

		document.addEventListener(visibilityChangeEvent, onVisibilityChange);
	},
	methods: {
		msg2client(msg) {
			console.log(msg);
			this.$root.clientFunc("msgFromH5", msg);
		},
		data2client(data) {
			console.log(data);
			this.$root.clientFunc("dataFromH5", data);
		},
		initPage() {
			this.shell = this.$global.shell;
		},
		setupIframeTracking() {
			const iframes = document.querySelectorAll("iframe");
			const tracker = new this.IframeTracker(this);
			iframes.forEach((iframe) => {
				tracker.addIframe(iframe, (iframeElement) => {
					console.log("Iframe 点击触发了!", iframeElement);
					console.log(iframeElement.src);
					const adContainer =
						iframeElement.closest("ins.adsbygoogle");
					if (adContainer) {
						this.msg2client("click_ad");
						// this.data2client(
						// 	JSON.stringify({ "ad_url:": iframeElement.src })
						// );
						tracker.trackEvent("ad_iframe_click", "click", {
							"data-ad-slot": adContainer.dataset.adSlot,
						});
					}
				});
			});
		},
		IframeTracker: class {
			constructor(vues, resolution = 200) {
				this.vues = vues; // 保存 Vue 实例
				this.resolution = resolution;
				this.iframes = [];
				this.interval = null;
				this.activeIframe = null; // 存储最近被点击的iframe
				this.setupVisibilityTracking();
			}

			addIframe(element, callback) {
				const adContainer = element.closest("ins.adsbygoogle");
				const iframe = {
					element: element,
					callback: callback,
					hasTracked: false,
					adSlot: adContainer ? adContainer.dataset.adSlot : null,
				};
				this.iframes.push(iframe);

				if (!this.interval) {
					this.interval = setInterval(
						() => this.checkClick(),
						this.resolution
					);
				}
			}

			checkClick() {
				const activeElement = document.activeElement;
				if (activeElement) {
					this.iframes.forEach((iframe) => {
						if (
							activeElement === iframe.element &&
							!iframe.hasTracked
						) {
							iframe.callback(iframe.element);
							iframe.hasTracked = true;
							this.activeIframe = iframe; // 更新最近被点击的iframe
						}
					});
				}
			}

			setupVisibilityTracking() {
				const hiddenProperty =
					"hidden" in document
						? "hidden"
						: "webkitHidden" in document
						? "webkitHidden"
						: "mozHidden" in document
						? "mozHidden"
						: null;
				const visibilityChangeEvent = hiddenProperty.replace(
					/hidden/i,
					"visibilitychange"
				);

				const onVisibilityChange = () => {
					if (!document[hiddenProperty]) {
						console.log("页面激活状态,返回页面?");
						this.activeIframe = null; // 重置最近被点击的iframe记录
					} else {
						console.log("页面非激活状态");
						if (this.activeIframe) {
							// 上报逻辑
							this.vues.msg2client("click_ad_leave");
							this.trackEvent("ad_iframe_click_leave", "click", {
								"data-ad-slot": this.activeIframe.adSlot,
							});
						}
					}
				};

				document.addEventListener(
					visibilityChangeEvent,
					onVisibilityChange
				);
			}

			trackEvent(eventName, eventValue, dataAttributes) {
				// 事件追踪逻辑
				console.log(
					`${eventName} with value ${eventValue} on`,
					dataAttributes
				);

				this.vues.$eventrackFb(eventName, eventValue, {
					...dataAttributes,
				});
			}
		},
		loadAdSenseScript() {
			if (window.adsbygoogle) {
				setTimeout(() => {
					this.displayAd();
					setTimeout(() => {
						this.setupIframeTracking();
					}, 500);
				}, 1500);
			} else {
				const script = document.createElement("script");
				script.src = this.shell.scriptUrl;
				script.crossOrigin = "anonymous";
				script.async = true;
				// 将脚本元素插入到文档的头部
				var header = document.getElementsByTagName("head")[0];
				header.appendChild(script);
				this.setupAdObserver(); //监听广告加载情况

				// 设置广告加载完成的回调函数
				script.onload = () => {
					this.msg2client("load_success_ad");
					this.showAD = true;
					setTimeout(() => {
						this.displayAd();
						setTimeout(() => {
							this.setupIframeTracking();
						}, 500);
					}, 1500);
				};
				// 设置广告加载失败的回调函数
				script.onerror = () => {
					console.log("AdSense script failed to load.");
					this.msg2client("load_fail_ad");
				};
			}
		},
		displayAd() {
			(window.adsbygoogle = window.adsbygoogle || []).push({});

			this.$nextTick(() => {
				this.msg2client("observe_ad_start");
				this.observeAdStatus();
			});
		},
		observeAdStatus() {
			let _this = this;
			const firstAdElement = this.$refs.ads1st[0];
			const observerCallback = (mutations) => {
				mutations.forEach((mutation) => {
					if (
						mutation.type === "attributes" &&
						mutation.attributeName === "data-ad-status"
					) {
						const target = mutation.target;
						const status = target.getAttribute("data-ad-status");
						if (target === firstAdElement) {
							if (status == "filled") {
								this.msg2client("filled_ad");
								this.firstAdVisible = status === "filled";
							}
							if (status == "unfilled") {
								this.msg2client("unfilled_ad");
							}
						}
						observerIntersection.observe(target);
					}
				});
			};

			const observer = new MutationObserver(observerCallback);

			const observerIntersection = new IntersectionObserver(
				(entries, msg) => {
					entries.forEach((entry) => {
						// 检查是否已经处理过该广告
						if (
							entry.isIntersecting &&
							!entry.target.dataset.viewed
						) {
							console.log(msg);
							console.log("Ad viewed", entry.target);
							this.msg2client("viewed_ad");
							// 标记广告为已处理
							entry.target.dataset.viewed = "true";
							console.log(entry.target.dataset.adSlot);
							_this.$eventrackFb("ad_fill_viewed", "expose", {
								"data-ad-slot": entry.target.dataset.adSlot,
							});

							// 取消观察当前元素
							observerIntersection.unobserve(entry.target);
						}
					});
				},
				{ threshold: 0.5 } // 至少50%的广告内容在视口中时触发
			);

			observer.observe(firstAdElement, {
				attributes: true,
			});
		},
		setupAdObserver() {
			let _this = this;
			//设置 MutationObserver 监听特定元素
			const observer = new MutationObserver((mutations) => {
				mutations.forEach((mutation) => {
					if (
						mutation.type === "childList" &&
						mutation.addedNodes.length > 0
					) {
						// 检查是否是广告相关的 DOM 变更
						if (mutation.target.matches("ins.adsbygoogle")) {
							_this.msg2client("loaded_ad");
							_this.$eventrackFb("ad_load_success", "load", {
								"data-ad-slot": mutation.target.dataset.adSlot,
							});
						}
					}
				});
			});

			// 观察器的配置（观察子节点变动）
			const config = { childList: true, subtree: true };

			// 开始观察当前组件根元素下的所有变化
			this.$refs.single && observer.observe(this.$refs.single, config);
		},

		getList() {
			return this.$global.server
				.getList({
					page: this.page,
					cid: this.shell.cid,
					sdu: 2,
					size: 30,
				})
				.then((res) => {
					this.page = this.page + 1;
					this.loading = false;
					this.isLoading = false;
					this.finished = !res.data.hasNext;
					if (res.result_code === 200) {
						let data = res.data.source;
						this.list.push(...data);

						if (this.list.length > 3 && !this.list[3].type) {
							this.list.splice(3, 0, {
								type: -1,
							});
						}
						if (this.list.length > 7 && !this.list[7].type) {
							this.list.splice(7, 0, {
								type: -2,
							});
						}
						if (this.list.length > 0) {
							this.isData = true;
						}
						this.hasNext = res.data.hasNext;
					} else {
						this.requirDataAlready = true;
					}
					if (!this.isinitialization) {
						this.isinitialization = true;
						this.$eventrackFb("show_pageNews_h5", "expose", {
							website: this.website,
							pve_cur: "/h5page/news/x/",
							type: this.type,
							state:
								res.result_code === 200 &&
								res.data.source.length > 1
									? 1
									: 0,
						});
					}
				})
				.catch((err) => {
					this.requirDataAlready = true;
					this.isinitialization = true;

					this.$eventrackFb("show_pageNews_h5", "expose", {
						website: this.website,
						pve_cur: "/h5page/news/x/",
						type: this.type,
						state: -1,
					});
				});
		},
		goLanding(id) {
			this.$eventrackFb("clicknews_h5", "click", {
				website: this.website,
				id: id,
			});
			this.$router.push({ name: "landing", query: { id: id } });
		},
		onRefresh() {
			this.finished = false;
			this.loading = true;
			this.page = 1;
			this.onLoad();
		},
		onLoad() {
			this.getList();
			console.log("onLoad");
		},
	},
};
</script>